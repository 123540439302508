import axios from 'axios';
import i18next from 'i18next';
import { consts } from './config';
import baseurl from './apihost.json';

const { CancelToken } = axios;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.baseURL = baseurl.url || undefined;

export const Axios = axios;

const handleError = (error) => {
  if (error.response) {
    let errorMessage;
    switch (error.response.status) {
      case 200: errorMessage = error.response?.data?.messages?.join(', ') || i18next.t('messages.UnsuccessfulRequest'); break;
      case 204: errorMessage = error.response?.data?.messages?.join(', ') || i18next.t('messages.NoDataReceived'); break;
      case 400:
        errorMessage = error.response?.data?.name === 'InvalidCredentials'
          ? i18next.t('login.LoginFailed')
          : error.response?.data?.messages?.join(', ') || i18next.t('messages.BadRequest');
        break;
      case 401: errorMessage = i18next.t('messages.Unauthorized'); break;
      case 404: errorMessage = error.response?.data?.messages?.join(', ') || i18next.t('messages.NotFound'); break;
      case 403: errorMessage = i18next.t('messages.Forbidden'); break;
      case 406: errorMessage = i18next.t('messages.NotAcceptable'); break;
      case 423: errorMessage = i18next.t('messages.Locked'); break;
      case 500:
      case 502: errorMessage = i18next.t('messages.ServerError'); break;
      case 503: errorMessage = i18next.t('messages.ServiceUnavailable'); break;
      default: errorMessage = null;
    }
    return errorMessage;
  }

  if (error.request) return i18next.t('messages.NoResponse');

  return `${i18next.t('messages.GeneralError')} ${error.message}`;
};

const onError = (error, errorHandler) => {
  const errorMesage = handleError(error);
  let innerErrors = [];

  if (!error.response || !error.response.data) {
    innerErrors.push(error.message);
    if (errorHandler) {
      errorHandler(errorMesage, innerErrors);
      return null;
    }
    return { errorMesage, innerErrors };
  }

  const _errorData = error.response.data;

  if (_errorData && typeof _errorData === 'string') {
    innerErrors.push(_errorData);
  }

  if (_errorData && typeof _errorData === 'object') {
    if (_errorData.messages) {
      if (Array.isArray(_errorData.messages)) innerErrors = [...innerErrors, ..._errorData.messages];
      else innerErrors = [...innerErrors, ...Object.values(_errorData.messages)];
    }

    if (_errorData.errors) {
      if (Array.isArray(_errorData.errors)) innerErrors = [...innerErrors, ..._errorData.errors];
      else innerErrors = [...innerErrors, ...Object.values(_errorData.errors)];
    }
  }

  if (errorHandler) {
    errorHandler(errorMesage, innerErrors);
    return null;
  }

  return { errorMesage, innerErrors };
};

export const getStorageItem = (key, prop = undefined) => {
  const data = sessionStorage.getItem(key);
  if (data) {
    const obj = JSON.parse(data);
    return prop ? obj[prop] : obj;
  }

  return null;
};

export const setStorageItem = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

const requestTimeout = (timeoutOverride) => {
  if (timeoutOverride || timeoutOverride === 0) return timeoutOverride;
  return getStorageItem(consts.timings)?.requestTimeout || consts.timeout;
};

export const getToken = () => {
  const token = getStorageItem(consts.authentication)?.token;
  return token ? `Bearer ${token}` : undefined;
};

export const getRefreshToken = () => {
  const auth = getStorageItem(consts.authentication);
  if (auth && auth.refreshToken) return auth.refreshToken;
  return '';
};

const addSiteIdQuery = (url) => {
  const site = getStorageItem(consts.currentSite);
  if (!site || site.siteId === 'no_site') return url;
  if (url.includes('?')) return `${url}&siteId=${site.siteId}`;
  return `${url}?siteId=${site.siteId}`;
};

export const GetCancelTokenSource = () => CancelToken.source();

const doRequest = (options, handlers, headers) => {
  const _options = {
    ...options,
    url: options.url,
    headers
  };

  axios.request(_options)
    .then((result) => {
      if (result && handlers.success) handlers.success(result.data, result.status);
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;
      onError(error, handlers.error);
    })
    .then(() => {
      if (handlers.afterall) handlers.afterall();
    });
};

const downloadRequest = (options, handlers) => {
  const _options = {
    ...options,
    url: addSiteIdQuery(options.url)
  };

  const handleArrayBufferErrorResponse = (error) => {
    try {
      const responseData = new TextDecoder('utf-8').decode(error.response.data);
      const data = JSON.parse(responseData);
      onError({ ...error, response: { ...error.response, data } }, handlers.error);
    } catch (ex) {
      onError({ message: ex.message }, handlers.error);
    }
  };

  axios.request(_options)
    .then((response) => {
      if (response.status !== 200) {
        handleArrayBufferErrorResponse(response);
        return;
      }

      let blobUrl;
      if (window.webkitURL) {
        blobUrl = window.webkitURL.createObjectURL(new Blob([response.data], { type: options.data }));
      } else if (window.URL && window.URL.createObjectURL) {
        blobUrl = window.URL.createObjectURL(new Blob([response.data], { type: options.data }));
      }

      const header = response.headers['content-disposition'];
      const fileName = header && header.includes(';')
        ? header.split(';').filter((x) => x.includes('filename='))[0]?.replace('filename=', '')?.trim()?.replace(/['"]+/g, '') || 'file'
        : 'file';

      if (handlers.success) handlers.success({ blobUrl, fileName });
      if (!_options.autoDownload) return;

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      link.click();
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;
      handleArrayBufferErrorResponse(error);
    })
    .then(() => {
      if (handlers.afterall) handlers.afterall();
    });
};

export const DownloadFile = (method, url, data, progress, success, error, afterall, cancelToken, timeoutOverride, autoDownload = true) => {
  const options = {
    method,
    url,
    data,
    timeout: timeoutOverride || 0,
    cancelToken,
    responseType: 'arraybuffer',
    autoDownload,
    onDownloadProgress: (e) => { if (progress) progress(e); }
  };

  downloadRequest(options, { success, error, afterall, progress });
};

export const UploadFile = (url, formData, success, progress, error, afterall, cancelToken, timeoutOverride) => {
  const options = {
    method: 'POST',
    url,
    data: formData,
    timeout: timeoutOverride || 0,
    cancelToken,
    onUploadProgress: (e) => { if (progress) progress(e); }
  };

  doRequest(options, { success, error, afterall }, { 'Content-Type': 'multipart/form-data' });
};

export const GetRequest = (url, success, error, afterall, cancelToken, timeoutOverride) => {
  const options = {
    method: 'GET',
    url,
    timeout: requestTimeout(timeoutOverride),
    cancelToken,
  };

  doRequest(options, { success, error, afterall });
};

export const PostRequest = (url, data, success, error, afterall, cancelToken, timeoutOverride) => {
  const options = {
    method: 'POST',
    url,
    data,
    cancelToken,
    timeout: requestTimeout(timeoutOverride),
  };

  doRequest(options, { success, error, afterall });
};

export const Request = (method, url, data, success, error, afterall, cancelToken, timeoutOverride) => {
  const options = {
    method,
    url,
    data,
    cancelToken,
    timeout: requestTimeout(timeoutOverride),
  };

  doRequest(options, { success, error, afterall });
};

const doRequestAsync = async (options) => {
  const _options = {
    ...options,
    url: options.url
  };
  try {
    const response = await axios.request(_options);
    return response?.data;
  } catch (error) {
    return onError({ success: false, error });
  }
};

export const GetRequestWithHeadersAsync = async (url, cancelToken, headers, timeoutOverride) => {
  const options = {
    headers,
    method: 'GET',
    url,
    timeout: requestTimeout(timeoutOverride),
    cancelToken,
  };

  const responseData = await doRequestAsync(options);
  return responseData;
};

export const GetRequestAsync = async (url, cancelToken, timeoutOverride) => {
  const options = {
    method: 'GET',
    url,
    timeout: requestTimeout(timeoutOverride),
    cancelToken,
  };

  const responseData = await doRequestAsync(options);
  return responseData;
};

export const PostRequestAsync = async (url, data, cancelToken, timeoutOverride) => {
  const options = {
    method: 'POST',
    url,
    data,
    cancelToken,
    timeout: requestTimeout(timeoutOverride),
  };

  const responseData = await doRequestAsync(options);
  return responseData;
};

export const RequestAsync = async (method, url, data, cancelToken, timeoutOverride) => {
  const options = {
    method,
    url,
    data,
    cancelToken,
    timeout: requestTimeout(timeoutOverride),
  };

  const responseData = await doRequestAsync(options);
  return responseData;
};
