/* eslint-disable no-param-reassign */
import i18next from 'i18next';
import { createSlice } from '@reduxjs/toolkit';
import { PostRequest, GetRequest, getStorageItem, setStorageItem } from '../utils/ApiClient';
import { consts, urls } from '../utils/config';
import { warning } from './messageSlice';

const HasTokens = (auth) => {
  const _auth = auth || getStorageItem(consts.authentication);
  return _auth
    && _auth.userName
    && _auth.token?.length > 0
    && _auth.refreshToken?.length > 0;
};

export const slice = createSlice({
  name: 'app',
  initialState: {
    isLoading: false,
    loginStatus: { error: null, isLoading: false },
    user: getStorageItem(consts.userdata),
    isAuthenticated: HasTokens(),
    isAuthorized: false,
    additionalBreadCrumbItems: [],
    clearBreadCrumb: true,
    lastSync: [],
    sidebar: {
      show: 'responsive',
      minimized: false,
      labels: true
    },
    failedTenants: [],
    appVersion: getStorageItem(consts.appVersion),
    organizations: []
  },
  reducers: {
    setFailedTenants: (state, { payload }) => {
      payload.forEach((tenant) => {
        if (!state.failedTenants.some((t) => t.id === tenant.id)) {
          state.failedTenants.push(tenant);
        }
      });
    },
    acknowledgeTenant: (state, { payload }) => {
      const _tenants = state.failedTenants;
      for (let i = 0; i < _tenants.length; i += 1) {
        if (_tenants[i].id === payload) {
          _tenants[i].acknowledged = true;
        }
      }
      state.failedTenants = _tenants;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setLoginStatus: (state, { payload }) => {
      state.loginStatus = { ...state.loginStatus, ...payload };
    },
    setUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
      setStorageItem(consts.userdata, state.user);
    },
    clearUser: (state) => {
      state.user = {};
    },
    setAuthenticated: (state, { payload }) => {
      state.isAuthenticated = HasTokens(payload);
      if (payload) setStorageItem(consts.authentication, payload);
    },
    setAuthorized: (state, { payload }) => {
      state.isAuthorized = payload;
    },
    setSidebar: (state, { payload }) => {
      state.sidebar = { ...state.sidebar, ...payload };
    },
    lastSync: (state, { payload }) => {
      const prev = state.lastSync.filter((s) => s.path !== payload);
      state.lastSync = [...prev, { path: payload, timeStamp: (new Date()).getTime() }];
    },
    setAppVersion: (state, { payload }) => {
      state.appVersion = payload;
      if (payload) setStorageItem(consts.appVersion, payload);
    },
    setOrganizations: (state, { payload }) => {
      state.organizations = payload;
      if (payload) setStorageItem(consts.organizations, payload);
    }
  },
});

export const {
  setOrganizations, setAppVersion, setFailedTenants, acknowledgeTenant, setIsLoading,
  setUser, clearUser, setLoginStatus, setAuthenticated, setAuthorized,
  setBreadCrumbItems, clearBreadCrumb, setSidebar, lastSync, resetLastBreadCrumbItem
} = slice.actions;

export const loadOrganizations = () => (dispatch) => {
  GetRequest(
    urls.organizations,
    (response) => {
      dispatch(setOrganizations(response?.data));
      if (response && !response.success && response.messages) {
        dispatch(warning(response.messages?.join(', ')));
      }
    },
    (error, innerErrors) => {
      console.error(error, ...innerErrors);
    },
  );
};

export const getAppSettings = () => (dispatch) => {
  dispatch(setIsLoading(true));
  GetRequest(
    urls.appSettings,
    (response) => {
      if (response?.success) {
        dispatch(setAppVersion(response.data));
        dispatch(setAuthorized(true));
      }
    },
    (error, innerErrors) => {
      console.error(error, ...innerErrors);
    },
    () => dispatch(setIsLoading(false))
  );
};

const clearSessionStorage = () => {
  const authSettings = JSON.parse(sessionStorage.getItem(consts.authSettings));
  sessionStorage.clear();
  if (authSettings) {
    sessionStorage.setItem(consts.authSettings, JSON.stringify(authSettings));
  }
};

export const logout = () => (dispatch) => {
  PostRequest(
    urls.signout,
    { logoutRequest: true },
    () => { /* ignore */ },
    () => { /* ignore */ },
    () => {
      clearSessionStorage();
      dispatch(setAuthenticated());
      dispatch(clearUser());
    }
  );
};

export const login = (credentials) => (dispatch) => {
  dispatch(setLoginStatus({ isLoading: true, error: null }));
  PostRequest(
    urls.signin,
    credentials,
    (auth) => {
      dispatch(setUser({
        userName: auth.userName,
        culture: auth.culture
      }));
      dispatch(setAuthenticated(auth));
      dispatch(getAppSettings());
      i18next.changeLanguage();
    },
    (error, innerErrors) => dispatch(setLoginStatus({ error: error || innerErrors?.join(', ') || i18next.t('login.LoginFailed') })),
    () => dispatch(setLoginStatus({ isLoading: false }))
  );
};

export const failedTenants = (state) => state.app.failedTenants;
export const sidebarMinimized = (state) => state.app.sidebar.minimized;
export const sidebarShow = (state) => state.app.sidebar.show;
export const currentUser = (state) => state.app.user;
export const loginStatus = (state) => state.app.loginStatus;
export const isAuthenticated = (state) => state.app.isAuthenticated;
export const isAuthorized = (state) => state.app.isAuthorized;
export const isLoading = (state) => state.app.isLoading;
export const appVersion = (state) => state.app.appVersion;
export const organizations = (state) => state.app.organizations;

export default slice.reducer;
